import axios from 'axios'
import mem from 'mem'
import store from '../store'
import { logout, setTokens } from '../features/user/userSlice'

const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/'

const baseURL = API_URL //'/'

const axiosPublic = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

const refreshTokenFn = async () => {
  const session = JSON.parse(localStorage.getItem('authTokens'))

  try {
    const response = await axiosPublic.post('/api/token/refresh/', {
      refresh: session?.refresh,
    })

    const authTokens = response.data

    if (!authTokens?.access) {
      localStorage.removeItem('authTokens')
      store.dispatch(logout())
    }

    localStorage.setItem('authTokens', JSON.stringify(authTokens))
    store.dispatch(setTokens(authTokens))

    return authTokens
  } catch (error) {
    localStorage.removeItem('authTokens')
    store.dispatch(logout())
  }
}

const maxAge = 10000

const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
})

const useAxios = () => {
  const axiosInstance = axios.create({
    baseURL,
  })

  axiosInstance.interceptors.request.use(
    async (config) => {
      const session = JSON.parse(localStorage.getItem('authTokens'))

      if (session?.access) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${session?.access}`,
        }
      }
      return config
    },
    (error) => Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true

        const result = await memoizedRefreshToken()

        if (result?.access) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${result?.access}`,
          }
        }

        return axios(config)
      }
      return Promise.reject(error)
    },
  )
  return axiosInstance
}

export default useAxios
